<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="removeFromTailsForm"
        ref="removeFromTailsForm"
        v-model="isFormValid"
        class="custom-form pt-6"
        @submit.prevent="submitTailRemove"
      >
        <v-col cols="12">
          <v-text-field
            v-model="reason"
            label="Powód usunięcia z ogonów"
            placeholder="Wpisz powód"
            outlined
            dense
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="removeFromTailsForm"
      >
        Usuń z ogonów
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },

  data() {
    return {
      reason: '',
      isFormValid: true
    }
  },
  computed: {
    ...mapState({
      item: state => state.layout.dialog.item,
      isProcessing: state => state.course.isProcessing
    })
  },
  methods: {
    ...mapActions({
      removeFromTails: 'course/removeFromTails',
      closeDialog: 'layout/closeDialog'
    }),
    submitTailRemove() {
      if (this.$refs.removeFromTailsForm.validate()) {
        const { id } = this.item
        const { reason } = this
        this.removeFromTails({
          id,
          reason
        }).then(() => {
          this.closeDialog()
        })
      }
    }
  }
}
</script>
